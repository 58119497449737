import React from 'react';
import '../loader.css';

function Loading() {
  return (
    <div className="container">
   
      
        <div className="loader"></div>
        <h2>Waiting for farmer to pay</h2>
      

  </div>
  )
}

export default Loading