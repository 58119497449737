// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image{
    display: flex;
    justify-content: center;
    margin-top: 45%;
}

h3{
    display: flex;
    justify-content: center;
  font-weight: bold;
  font-size:1.7em;
}`, "",{"version":3,"sources":["webpack://./src/components/farmer/timeout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;EACzB,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".image{\n    display: flex;\n    justify-content: center;\n    margin-top: 45%;\n}\n\nh3{\n    display: flex;\n    justify-content: center;\n  font-weight: bold;\n  font-size:1.7em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
